import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import './style.scss'

class ContentList extends React.Component {
  state = {
    open: false,
    init: false,
    sticky: false,
    offset: 0,
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.state.open) {
      window && require('disable-scroll').default.on()
    } else {
      window && require('disable-scroll').default.off()
    }
    return null
  }
  onScroll = e => {
    const offset = (e.target.scrollingElement || e.target.documentElement)
      .scrollTop
    this.setState({ sticky: offset > 45, offset: offset })
  }
  componentDidMount = () => {
    window.addEventListener('scroll', this.onScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  render() {
    const { prefix, isMobile, content, currentPathname, withIcon } = this.props

    const list = content.edges
      .sort(
        (a, b) =>
          Number(a.node.frontmatter.position) -
          Number(b.node.frontmatter.position),
      )
      .map(({ node }) => ({
        link: `/${prefix}/${node.frontmatter.path}/`,
        name: node.frontmatter[`name_${prefix}`],
        short_description: node.frontmatter[`short_description_${prefix}`],
        icon: node.frontmatter.icon,
      }))

    const selected = list.filter(l => l.link.includes(currentPathname))[0]

    if (isMobile) {
      if (withIcon) {
        return (
          <div className="content-list content-list__mobile">
            {list
              .filter(el => el.link !== '/th/list-your-car/')
              .map(content => {
                return (
                  <Link key={content.link} className="item" to={content.link}>
                    <img src={content.icon} alt={content.name} />
                    <div className="description">
                      <h3>{content.name}</h3>
                      <p>{content.short_description}</p>
                    </div>
                  </Link>
                )
              })}
          </div>
        )
      }
      return (
        <>
          <div style={{ height: 45 }} />
          <div className="content-list__header--mobile">
            <Link
              className="back"
              to={`/${prefix}/help-center`}
              style={{ flex: 1 }}
            >
              ย้อนกลับ
            </Link>
            <div className="header" style={{ flex: 3 }}>
              {selected.name}
            </div>
            <div
              className={
                this.state.init
                  ? `menu ${this.state.open ? 'menu__open' : 'menu__close'}`
                  : 'menu'
              }
              style={{ flex: 1 }}
              onClick={() => {
                this.setState({ open: !this.state.open, init: true })
              }}
            >
              <span>หัวข้ออื่นๆ</span>
              <ul>
                {list.map(item => (
                  <li key={item.name}>
                    <Link
                      to={item.link}
                      className={item.name === selected.name ? 'selected' : ''}
                    >
                      <div className="item">{item.name}</div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={`background__gray ${this.state.open ? 'open' : 'close'}`}
            onClick={() => this.setState({ open: false })}
            style={{ zIndex: 1 }}
          />
        </>
      )
    }
    if (this.props.listView) {
      return (
        <div className="content-list__listview">
          {list.map(content => {
            return (
              <Link
                key={content.link}
                className={
                  content.name === selected.name ? 'item selected' : 'item'
                }
                to={content.link}
              >
                {content.name}
              </Link>
            )
          })}
        </div>
      )
    }
    return (
      <div className="flexbox--center">
        <div className="content-list">
          {list
            .filter(el => el.link !== '/th/list-your-car/')
            .map(content => {
              return (
                <Link key={content.link} className="item" to={content.link}>
                  <img src={content.icon} alt={content.name} />
                  <div className="description">
                    <h3>{content.name}</h3>
                    <p>{content.short_description}</p>
                  </div>
                </Link>
              )
            })}
        </div>
      </div>
    )
  }
}
const ContentListQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        content: allMarkdownRemark(
          filter: { fields: { collection: { eq: "content" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                path
                position
                name_th
                name_en
                icon
                short_description_th
                short_description_en
              }
            }
          }
        }
      }
    `}
    render={data => <ContentList {...data} {...props} />}
  />
)

export default ContentListQuery
