import React, { Component } from 'react'
import Layout from '../../components/layout'
import ContentList from '../../components/content-list'
import SEO from '../../components/seo'
import './style.scss'

export default class HelpCenter extends Component {
  state = {
    isMobile: true,
  }
  componentDidMount() {
    const initialWidth = window.innerWidth
    this.setState({ isMobile: initialWidth < 768 ? true : false })

    let widthChecker = window.matchMedia('(max-width: 768px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
  }
  render() {
    const { isMobile } = this.state
    const { prefix } = this.props
    return (
      <div className="help-center">
        <Layout isMobile={isMobile} prefix={prefix} theme="black">
          <SEO
            title="Help Center"
            prefix={prefix}
            path="help-center"
            canonical="https://help.drivehub.co/th/help-center/"
            description="ให้เราช่วยอะไรคุณได้บ้าง ติดต่อเราได้อย่างไร ..."
            keywords="วิธีการเช่า, บริการของเรา, การรับรถ, ติดต่อ, Drivehub"
          />
          <div className="container">
            <h1 className="help-center__header">ให้เราช่วยอะไรคุณได้บ้าง?</h1>
            <ContentList prefix={prefix} withIcon isMobile={isMobile} />
          </div>
        </Layout>
      </div>
    )
  }
}
